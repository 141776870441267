<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-flex justify-content-between">
            <h3>View Incident</h3>
            <b-btn variant="primary" :to="{ name: 'Incident Reports' }">
              <i class="fa fa-arrow-left mr-1"></i>
              Go to Incidents
            </b-btn>
          </div>
        </div>

        <b-card class="" v-if="loading">
          <b-skeleton animation="wave" width="85%"></b-skeleton>
          <b-skeleton animation="wave" width="67%"></b-skeleton>
          <b-skeleton animation="wave" width="70%"></b-skeleton>
          <b-skeleton animation="wave" width="82%"></b-skeleton>
          <b-skeleton animation="wave" width="65%"></b-skeleton>
          <b-skeleton animation="wave" width="90%"></b-skeleton>
          <b-skeleton animation="wave" width="95%"></b-skeleton>
          <b-skeleton type="button"></b-skeleton>
        </b-card>

        <div class="widget-content widget-content-area" v-else>
          <b-alert
            :variant="incident.status == 'resolved' ? 'success' : 'danger'"
            show
          >
            <b>INCIDENT STATUS: {{ incident.status | uppercase }}</b>
          </b-alert>

          <div class="table-responsive">
            <table class="table">
              <tr class="table-secondary">
                <th width="30%">Creator</th>
                <td>
                  {{
                    incident.user
                      ? `${incident.user.firstname} ${incident.user.lastname}`
                      : ""
                  }}<br />
                  <small class="text-muted">{{
                    incident.user ? `${incident.user.role}` : ""
                  }}</small>
                </td>
              </tr>
              <tr>
                <th>Title</th>
                <td>{{ incident.title }}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td :class="'table-' + categoryClass()">
                  {{ incident.category | capitalize }}
                </td>
              </tr>
              <tr>
                <th>Description</th>
                <td style="white-space: pre-wrap">
                  {{ incident.description }}
                </td>
              </tr>
              <tr>
                <th>Departments to Handle</th>
                <td>
                  <b-badge
                    variant="info"
                    class="mr-2 shadow-none"
                    v-for="(department, index) in incident.dept_to_handle.split(
                      ','
                    )"
                    :key="index"
                  >
                    {{ department | startCase }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th>Action Taken</th>
                <td style="white-space: pre-wrap">
                  {{ incident.actiontaken }}
                </td>
              </tr>
              <tr>
                <th>Recommendation</th>
                <td style="white-space: pre-wrap">
                  {{ incident.recommendation }}
                </td>
              </tr>
              <tr>
                <th>Documents</th>
                <td>
                  <b-badge
                    variant="info"
                    class="mr-2 shadow-none"
                    v-for="(document, index) in incident.documents"
                    :key="index"
                    target="_blank"
                    :href="`${url}/uploads/incidents/${document.filename}`"
                  >
                    {{ document.filename }} ({{ document.size | prettyBytes }})
                  </b-badge>
                  <b-badge
                    variant="info"
                    class="mr-2 shadow-none"
                    v-for="(attachment, index) in incident.attachments"
                    :key="index"
                    target="_blank"
                    :href="attachment.original_url"
                  >
                    {{ attachment.file_name }} ({{
                      attachment.size | prettyBytes
                    }})
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th>Created Date</th>
                <td>{{ formatFancyDateFull(incident.created_at) }}</td>
              </tr>
              <tr>
                <th>Last Modified Date</th>
                <td>{{ formatFancyDateFull(incident.updated_at) }}</td>
              </tr>
            </table>
          </div>

          <!-- Actions -->
          <div class="mt-4">
            <div class="bg-dark p-2">Actions</div>

            <b-alert
              variant="warning"
              v-if="incident.actions.length < 1"
              class="mb-0"
              show
            >
              No action has been submitted!
            </b-alert>

            <b-list-group class="rounded-0" v-else>
              <b-list-group-item
                class="flex-column align-items-start"
                v-for="(action, index) in incident.actions"
                :key="index"
              >
                <div class="d-flex justify-content-between mb-2">
                  <span class="w-75">
                    <h6 class="font-weight-bold mb-0">
                      {{
                        action.user
                          ? `${action.user.firstname} ${action.user.lastname}`
                          : ""
                      }}
                    </h6>
                    <small>{{ formatFancyDateFull(action.created_at) }}</small>
                  </span>
                  <small>{{ action.user ? action.user.role : "" }}</small>
                </div>

                <p class="py-2" style="white-space: pre-wrap">
                  {{ action.description }}
                </p>

                <div v-if="action.dept_to_notify">
                  <b-badge
                    variant="info"
                    class="mr-2 shadow-none"
                    v-for="(department, index) in action.dept_to_notify.split(
                      ','
                    )"
                    :key="index"
                  >
                    <i class="fa fa-bell"></i>
                    {{ department.trim() | startCase }}
                  </b-badge>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>

          <!-- Action Form -->
          <div class="mt-5" v-if="incident.status !== 'resolved'">
            <hr />

            <form
              method="POST"
              @submit.prevent="saveIncidentAction()"
              @keydown="incidentActionForm.onKeydown($event)"
            >
              <AlertError :form="incidentActionForm" />

              <b-form-group
                description="What have you done or what do you plan to do?"
                label="Action Taken or To Be Taken *"
                label-for="description"
                :invalid-feedback="incidentActionForm.errors.get('description')"
                :state="!incidentActionForm.errors.has('description')"
              >
                <Mentionable :keys="['@']" :items="users" insert-space>
                  <b-form-textarea
                    id="description"
                    v-model="incidentActionForm.description"
                    placeholder="Enter your description"
                    rows="3"
                    :state="
                      incidentActionForm.errors.has('description')
                        ? false
                        : null
                    "
                    trim
                    required
                  ></b-form-textarea>

                  <template #no-result>
                    <div class="text-muted">No result</div>
                  </template>

                  <template #item-@="{ item }">
                    <div class="text-left">
                      {{ `${item.firstname} ${item.lastname}` }}
                    </div>
                  </template>
                </Mentionable>
              </b-form-group>

              <b-form-group
                description="Departments to notify"
                label="Department(s) to notify"
                label-for="dept_to_notify"
                :invalid-feedback="
                  incidentActionForm.errors.get('dept_to_notify')
                "
                :state="!incidentActionForm.errors.has('dept_to_notify')"
              >
                <b-form-tags
                  input-id="dept_to_notify"
                  v-model="incidentActionForm.dept_to_notify"
                  separator=",;"
                  placeholder="Enter a department separated by space, comma or semicolon"
                  :state="
                    incidentActionForm.errors.has('dept_to_notify')
                      ? false
                      : null
                  "
                  add-on-change
                  remove-on-delete
                  no-outer-focus
                >
                  <template
                    v-slot="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      disabled,
                      removeTag,
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="info"
                        >
                          {{ tag | startCase }}
                        </b-form-tag>
                      </li>
                    </ul>
                    <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || availableDepartments.length === 0"
                      :options="availableDepartments"
                    >
                      <template #first>
                        <!-- This is required to prevent bugs with Safari -->
                        <option disabled value="">
                          Choose a department...
                        </option>
                      </template>
                    </b-form-select>
                  </template>
                </b-form-tags>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  v-model="incidentActionForm.status"
                  value="resolved"
                  unchecked-value="unresolved"
                  switch
                >
                  Resolve the Incident
                </b-form-checkbox>
              </b-form-group>

              <b-button variant="success" type="submit">
                <b-spinner
                  label="Loading"
                  v-if="incidentActionForm.busy"
                  small
                ></b-spinner>
                Submit New Action
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Mentionable } from "vue-mention";
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";
import { mapGetters } from "vuex";

export default {
  name: "ViewIncident",
  components: {
    Mentionable,
  },
  props: ["id"],
  data() {
    return {
      loading: true,
      incident: null,
      incidentActionForm: new this.$Form({
        description: "",
        dept_to_notify: [],
        status: "",
      }),
      users: [],
      userRoles: this.$root.userRoles,
    };
  },
  computed: {
    ...mapGetters(["url"]),
    availableDepartments() {
      return this.userRoles.filter(
        (role) => this.incidentActionForm.dept_to_notify.indexOf(role) === -1
      );
    },
  },
  methods: {
    categoryClass() {
      let variant = "light";

      switch (this.incident.category.toLowerCase()) {
        case "doctor":
          variant = "dark";
          break;

        case "sponsor":
          variant = "warning";
          break;

        case "elderly":
          variant = "danger";
          break;

        case "other":
          variant = "secondary";
          break;

        default:
          break;
      }

      return variant;
    },
    fetchIncident() {
      this.loading = true;

      http
        .get(endpoints.FETCH_SINGLE_INCIDENT.replace(":id", this.id))
        .then((response) => {
          this.incident = response;
          this.loading = false;
          this.incidentActionForm.status = response.status;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    fetchUsers() {
      http
        .get(`${endpoints.GET_ALL_USERS}?fields[users]=id,firstname,lastname`)
        .then((response) => {
          this.users = response.map((user) => {
            user.value = user.firstname;
            return user;
          });
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    saveIncidentAction() {
      this.incidentActionForm
        .post(endpoints.CREATE_INCIDENT_ACTION.replace(":id", this.id))
        .then((response) => {
          this.$toast.success(response);
          this.fetchIncident();
          this.incidentActionForm.reset();
        })
        .catch((error) => {
          this.$error.error(error);
        });
    },
  },
  mounted() {
    this.fetchIncident();
    this.fetchUsers();
  },
};
</script>

<style>
.mention-item {
  padding: 5px;
  cursor: pointer;
}

.mention-selected {
  background-color: var(--primary);
  color: var(--white);
}

.tooltip {
  display: block !important;
  z-index: 9999;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-tooltip-open {
  z-index: 9999;
}
</style>
